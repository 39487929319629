import React from 'react';
import styled from "styled-components";
import { images } from "./../Common/Consts";
import Authentication from "./Authentication";

const backgroundImage = process.env.REACT_APP_IMAGES_URL + "/" + process.env.REACT_APP_SITE + '/back_header.png';

const StyledHeaderDiv = styled.div`
    clear: both;
    width: 100%;
    height: 140px;
    border: 0px solid white;
    background-image: url(${backgroundImage});
    background-repeat: repeat-x;
    background-attachment: fixed;
    overflow: hidden;
    display: flex;
    justify-content: center;    

    @media (min-width: 400px) {
    }
    
    @media (min-width: 768px) {
    }

    @media (min-width: 900px) {      
    }
`;

const StyledHeaderSizeDiv = styled.div`
    clear: both;
    width: 100%;
    height: 140px;
    border: 0px solid black;
    @media (min-width: 400px) {
    }
    
    @media (min-width: 768px) {
    }

    @media (min-width: 1100px) { 
        width: 1100px;
    }
`;

const StyledHeaderContainerDiv = styled.div`
    height: 140px;
    width: calc(100% - 40px);
    @media (min-width: 400px) {
        width: calc(100% - 60px);
    }
    border: 0px solid red;
    display: flex;
    justify-content: space-between;
`;

const StyledLogoDiv = styled.div` 
    border: 0px solid purple;
    float: left;
	margin-top: 16px;
	vertical-align: middle;
    left: 0px;
	height: 120px;
    min-width: 25%;
    border: 0px solid green;
    max-width: 262px;
    @media (min-width: 400px) {
    }
    
    @media (min-width: 768px) {
    }

    @media (min-width: 900px) {
    }
`;

const AuthDiv = styled.div` 
    float: right;
	margin-top: 0.5vh;
	height: 130px;
    max-width: 450px;
    border: 0px solid yellow;

    @media (min-width: 400px) {
    }
    
    @media (min-width: 768px) {
    }

    @media (min-width: 900px) {
        flex-wrap: nowrap;
    }
`;

const Logo = styled.img`
    width: 100%;
    max-height: 120px,
    border:0
    height: auto;
    max-width: 25vw;
  }
`


const Header: React.FC = () => {
	
    return (
        <StyledHeaderDiv className="HeaderDiv">
            <StyledHeaderSizeDiv className = "HeaderSizeDiv" >
                <StyledHeaderContainerDiv className="HeaderContainterDiv">
                    <StyledLogoDiv>
                        <Logo src={images.SnttcLogo} alt="logo" />
                    </StyledLogoDiv>
                    <AuthDiv>
                        <Authentication/>
                    </AuthDiv>
                </StyledHeaderContainerDiv>
            </StyledHeaderSizeDiv>
        </StyledHeaderDiv>
    )
};
  
export default Header

