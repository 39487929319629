import React, { PropsWithChildren } from 'react';
import styled from "styled-components";
import { useWindowDimensions, log } from "../Common/Utils";
import { useSnttcStateContext } from './../App/SnttcContext';

const localLogOverrule= false;

const MainContentDiv = styled.div<{ $maincontentheight: string }>`
    position: absolute;
    width: 100%;
    height: ${props => (props.$maincontentheight ? props.$maincontentheight : '600px')};
    top: 140px;
    margin: 0 0 0 0;
    padding: 0;
    background-color: #D0D1CF;
    background-repeat: repeat-x;
    background-attachment: fixed;
    overflow: hidden;
    display: flex;
    justify-content: center;
`;

const ContentDiv = styled.div<{ $maincontentheight: string }>` 
    border: 0px solid yellow;
    height: ${props => (props.$maincontentheight ? props.$maincontentheight : '600px')};
    width: calc(100% - 4px);
    @media (min-width: 900px) {
      width: 900px;
    }
`;

export default function BaseLayout (
    { children }: PropsWithChildren
  ) {
    const { height }  = useWindowDimensions();
    const { state } = useSnttcStateContext();

    const renderChildren = () => {
      return React.Children.map(children, (child) => {
        // @ts-ignore
        return React.cloneElement(child, { $maincontentheight: (height-8)+'px' } );
      });
    }

    log(state.webdebug||localLogOverrule,"BaseLayout","Default","height="+height+".");

    return (
      <MainContentDiv $maincontentheight={height +'px'} className={"MainContentDiv"}>
        <ContentDiv  $maincontentheight={(height-4) +'px'} className={"ContentDiv"}>
          {renderChildren()}
        </ContentDiv>
      </MainContentDiv>
    );
}