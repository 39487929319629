
export const ApiRoutes = {
    frenoy: {
        frenoyGetPlayerlist: process.env.REACT_APP_API_URL+'/frenoy/playerlist',
        frenoyGetTeamslist: process.env.REACT_APP_API_URL+'/frenoy/teamslist',
        frenoyGetRanklist: process.env.REACT_APP_API_URL+ '/frenoy/ranklist',
        frenoyGetCalendarAndResults: process.env.REACT_APP_API_URL+ '/frenoy/calendarAndResults',
        frenoyGetPlayweeks: process.env.REACT_APP_API_URL + '/frenoy/getPlayweeks',
        frenoyGetTeamSetups: process.env.REACT_APP_API_URL + '/frenoy/getPloegopstelling',

        frenoyPostTeamSetupUpdate: process.env.REACT_APP_API_URL+'/frenoy/PloegOpstellingUpdate?debug',
    },
    menu: {
        menulist : process.env.REACT_APP_API_URL+'/menu/list',
    },
    leden: {
        ledenlist: process.env.REACT_APP_API_URL+'/leden/list',
        lid: process.env.REACT_APP_API_URL+'/leden/id/:id',
        toegang: process.env.REACT_APP_API_URL+'/settings/machtigingenList',
        inschrijving: process.env.REACT_APP_API_URL+'/settings/inschrijvingenList',
        clubgegeven: process.env.REACT_APP_API_URL+'/settings/clubgegevensList',
    },
    settings:{
        seizoen: process.env.REACT_APP_API_URL+'/settings/seizoen',
        prijzen: process.env.REACT_APP_API_URL+'/settings/prijzen',
    },
    betalingen: {
        lijst: process.env.REACT_APP_API_URL+'/betalingen/list',
        lid: process.env.REACT_APP_API_URL+'/betalingen/id/:id',
        openstaande: process.env.REACT_APP_API_URL+'/betalingen/openstaande',
        afwijkende: process.env.REACT_APP_API_URL+'/betalingen/afwijkende',
    }

}